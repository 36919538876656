<template>
  <div id="addBank" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5">EDITAR BANCO</v-row>
      <v-container fluid class="addCategory2">
        <v-row no-gutters justify="end" class="formulariosTarget target160">
          <v-col cols="12" lg="5" md="4" sm="6" class="ml-5">
            <v-row>
              <div class="form-group mt-2">
                <label for="keyCfdi">Clave Banco (CFDI)</label>
                <input
                  type="text"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  name="keyCfdi"
                  id="keyCfdi"
                  v-model="bank.claveCFDI"
                  required
                  maxlength="3"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="keySpei">Clave Banco SPEI</label>
                <input
                  type="text"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  name="keySpei"
                  id="keySpei"
                  v-model="bank.claveSPEI"
                  required
                  maxlength="18"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="name">Nombre Comercial</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="bank.nombreCorto"
                  required
                  maxlength="40"
                  @keypress="isLetter($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="reason">Razón Social</label>
                <input
                  type="text"
                  name="reason"
                  id="reason"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="bank.razonSocial"
                  required
                  maxlength="400"
                  @keypress="isLetter($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="startDate">Fecha Inicio de Vigencia</label>
                <input
                  type="date"
                  name="startDate"
                  id="startDate"
                  class="inputs"
                  autocomplete="false"
                  required
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="finishDate">Fecha Fin de Vigencia</label>
                <input
                  type="date"
                  name="finishDate"
                  id="finishDate"
                  class="inputs"
                  autocomplete="false"
                  required
                />
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="validacion()">
                Guardar
              </button>
              <button class="botonAmarilloDerecho" @click="regresar()">
                Regresar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            {{ respuesta }}
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="actualizar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      message: "",
      errors: [],
      bank: [
        {
          id: "2",
          spei: "10584521004651",
          cfdi: "301",
          nombreComercial: "BANCOMEXT",
          razonSocial:
            "Banco Nacional de Comercio Exterior, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo",
          inicioVigencia: "2020/04/20",
          finVigencia: "2020/05/20",
        },
      ],
      error: false,
      respuesta: "",
      confirmation: false,
      advice: false,
    };
  },
  methods: {
    recuperar() {
      this.bank = [];
      this.show = true;
      axios
        .get(Server + "/bancos/" + localStorage.idBank, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.bank = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    aux() {
      this.confirmation = false;
      this.$router.push("/banks");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    isLetter: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion() {
      this.errors = [];
      this.message = "BANCO";
      this.validaCfdi();
      this.validaSpei();
      if (this.bank.nombreCorto == "" || this.bank.nombreCorto == " ") {
        this.errors.push("Se debe especificar el nombre comercial.");
      }
      if (this.bank.razonSocial == "" || this.bank.razonSocial == " ") {
        this.errors.push("Se debe especificar la razón social.");
      }
      /* if (this.bank[0].inicioVigencia == "") {
        this.errors.push("Se debe especificar la fecha de inicio de vigencia.");
      }
      if (this.bank[0].finVigencia == "") {
        this.errors.push("Se debe especificar la fecha de fin de vigencia.");
      } */
      if (this.errors.length == 0) {
        this.respuesta = "Esta seguro modificar el banco.";
        this.advice = true;
      } else {
        this.respuesta = "Por favor corrige el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    validaCfdi() {
      //console.log(this.keyCfdi);
      var count = this.bank.claveCFDI;
      if (count.length != 3) {
        this.errors.push("Clave Banco CFDI incorrecto");
        return false;
      } else {
        //console.log("Correcto");
        //console.log(count.length);
        return false;
      }
    },
    validaSpei() {
      //console.log(this.keySpei);
      var key = this.bank.claveSPEI;
      if (key.length != 18) {
        this.errors.push("Clave Banco SPEI incorrecto");
        return false;
      } else {
        //console.log("Correcto");
        //console.log(key.length);
        return false;
      }
    },
    regresar() {
      this.$router.push("/banks");
    },
    cancelar() {
      this.name = "";
      this.reason = "";
      this.keyCdfi = "";
      this.keySpei = "";
      this.startDate = "";
      this.finishDate = "";
      this.$router.push("/banks");
    },
    actualizar() {
      this.show = true;
      axios
        .put(
          Server + "/bancos/" + this.bank.id,
          {
            id: this.bank.id,
            ClaveCFDI: this.bank.claveCFDI,
            ClaveSPEI: this.bank.claveSPEI,
            NombreCorto: this.bank.nombreCorto,
            RazonSocial: this.bank.razonSocial,
            /* inicioVigencia: this.startDate,
          finVigencia: this.finishDate, */
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.bank = [];
          this.message = "Confirmación";
          this.respuesta = "El Banco fue actualizado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.bank = [];
            this.message = "Aviso";
            this.respuesta = "El Banco no fue actualizado.";
            this.confirmation = true;
          }
        });
    },
  },
  created() {
    this.recuperar();
  },
};
</script>